import Head from "next/head";
import Link from "next/link";
import { useTranslation } from "react-i18next";
import Button from "components/Button/Button";
import { StatusCode } from "types";
import styles from "./ErrorPage.module.css";

type ErrorPageProps = {
  statusCode: StatusCode;
};

const ErrorPage = ({ statusCode }: ErrorPageProps) => {
  const { t, ready } = useTranslation("common");

  if (!ready) {
    return null;
  }

  const actionText = t(`error.${statusCode}.action`);

  return (
    <>
      <Head>
        <title>{t(`error.${statusCode}.title`)}</title>
        <meta name="description" content={t(`error.${statusCode}.message`)} />
      </Head>
      <main className={styles["error-page"]}>
        <h1>
          <span className={styles["status-code"]}>{statusCode}</span>
          <span className={styles["line"]} />
          <span className={styles["title"]}>
            {t(`error.${statusCode}.title`)}
          </span>
        </h1>
        <p>{t(`error.${statusCode}.message`)}</p>
        {statusCode === 404 ? (
          <Link href="/">
            <Button>{actionText}</Button>
          </Link>
        ) : (
          <Button onClick={() => window.location.reload()}>{actionText}</Button>
        )}
      </main>
    </>
  );
};

export default ErrorPage;
